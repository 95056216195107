<template lang="pug">
  div.d-flex.flex-column
    HeaderGrid(:name="name" :uid="uid" :dateRanger="dateRanger" :tvaModeButton="tvaModeButton" :addButton="addButton" :searchButton="searchButton" :tagguerButton="tagguerButton" :selectedRows="selectedRows" @onFilterTextBoxChanged="onFilterTextBoxChanged" @selectParamerterAction="selectParamerterAction" @clickAction="clickAction" :searchPlaceholder="searchPlaceholder" :archiveMode="archiveMode" :archive="archive" @refreshRow="refreshRow" :indexSearch="indexSearch")
    .d-flex.w-100.h-100(style="overflow: hidden;")
      .text-center.flex-center.w-100(v-if='isLoadingContractsList || isLoadingContractsListArchived || !headerLoaded')
        .loading-bg-inner
          .loader
            .outer
            .middle
            .inner
        .mt-5
          br
          br
          br
          | Chargement de la liste des contrats...
      sync-grid#overviewgrid(ref="overviewgrid" v-else :uid="uid" :gridData="dataFiltered" :name="name" :headerData="headerData" @rowClicked="onRowClicked" @setTagsSelected="setTagsSelected" @setSelectedRows="setSelectedRows" :tagguerButton="tagguerButton" :allowGrouping="allowGrouping")
  </template>

<script>
import { mapActions, mapGetters } from "vuex";

import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import { setColumnsByObject } from "@/types/api-orisis/library/ObjectOperations.ts";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import SyncGrid from "@/components/global/grid/Grid.vue";
import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";

export default {
  components: {
    SyncGrid,
    HeaderGrid,
  },
  data() {
    return {
      indexSearch: 0,
      name: "contract",
      uid: "contracts",
      archive: false,
      headerData: [],
      headerLoaded: false,
      tabActive: "all",
      selectedRows: [],

      dateRanger: false,
      tvaModeButton: false,
      addButton: true,
      searchButton: true,
      tagguerButton: false,
      archiveMode: true,
      searchPlaceholder: "Rechercher un contrat",
      allowGrouping: false,
    };
  },
  beforeMount() {
    this.headerData = [
      {
        type: "checkbox",
        headerText: null,
        width: 30,
        maxWidth: 30,
        visible: false,
      },
      {
        type: "string",
        field: "label",
        headerText: "Nom",
        width: "auto",
        minWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "nature",
        headerText: "Nature",
        width: 120,
        minWidth: 120,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        template: "contractNatureTemplate",
        filterTemplate: "contractNatureFilterTemplate",
      },
      {
        type: "string",
        field: "status",
        headerText: "Statut",
        width: 120,
        minWidth: 120,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        template: "contractStatusTemplate",
        filterTemplate: "contractStatusFilterTemplate",
      },
      {
        type: "string",
        field: "hoursDayDefault",
        headerText: "Heures (jour)",
        width: "auto",
        minWidth: 150,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "hoursDayWarning",
        headerText: "Seuil d'alerte (jour)",
        width: "auto",
        minWidth: 150,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "hoursDayLimit",
        headerText: "Limite (jour)",
        width: "auto",
        minWidth: 150,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "hoursWeekDefault",
        headerText: "Heures (semaine)",
        width: "auto",
        minWidth: 150,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "hoursWeekWarning",
        headerText: "Seuil d'alerte (semaine)",
        width: "auto",
        minWidth: 150,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "hoursWeekLimit",
        headerText: "Limite (semaine)",
        width: "auto",
        minWidth: 150,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
    ];
  },
  computed: {
    ...mapGetters([
      "contractsList",
      "contractsListArchived",
      "isLoadingContractsList",
      "isLoadingContractsListArchived",
    ]),
    dataFiltered() {
      if (this.archive) {
        return this.$store.getters[this.name + "sListArchived"];
      } else {
        return this.$store.getters[this.name + "sList"];
      }
    },
  },
  created() {
    this.getContracts({}).then(async (res) => {
      if (res.length) {
        await this.setColumnsByObject(res[0], null, this);
        this.indexSearch++;
      }
      this.headerLoaded = true;
    });
  },
  methods: {
    ...mapActions(["getContracts", "getContractsArchived"]),
    formatCurrency,
    setColumnsByObject,
    onFilterTextBoxChanged(search) {
      this.$refs.overviewgrid.onFilterTextBoxChanged(search);
    },
    clickAction() {
      this.$router.push({
        name: "edit-contract",
        params: { id: null, archive: this.archive },
      });
    },
    selectParamerterAction(args) {
      if (["csv", "xls", "print"].includes(args.item.type)) {
        this.$refs.overviewgrid.exportData(args.item.type);
      } else if (args.item.type == "archive") {
        this.getContractsArchived({});
        this.archive = true;
      } else if (args.item.type == "current") {
        this.getContracts({});
        this.archive = false;
      } else {
        this.$refs.overviewgrid.$refs.overviewgrid.openColumnChooser();
      }
    },
    setSelectedRows(val) {
      this.selectedRows = val;
    },
    onRowClicked(event) {
      this.$router.push({
        name: "edit-contract",
        params: { id: event.rowData.id, archive: this.archive },
      });
    },
    refreshRow(val) {
      this.$refs.overviewgrid.$refs.overviewgrid.setRowData(val.id, val);
    },
    setTagsSelected(val) {},
    refreshGrid(res) {
      this.$refs.overviewgrid.$refs.overviewgrid.refresh();
    },
  },
};
</script>
